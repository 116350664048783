import React, { CSSProperties, useEffect, useMemo, useState } from "react"
import * as Contents from "./contents"
import Header from "."
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { between, down, up } from "styled-breakpoints"
import Hamburger from "../icons/hamburger"
import styled from "styled-components"
import { CenterContent } from "./styles"

interface Props {
  title?: string
  divider?: boolean
  showHypothesisButton?: boolean
  progress?: React.ReactElement
}

const AdvancedHeader: React.FC<Props> = ({
  title,
  divider,
  progress,
  showHypothesisButton,
}) => {
  const isMobile = useBreakpoint(down("md"))
  const isTablet = useBreakpoint(between("sm", "lg", "portrait"))
  const isLaptop = useBreakpoint(between("md", "lg"))
  const [isClosed, setIsClosed] = useState(true)

  const toggleClosed = () => setIsClosed(prev => !prev)

  useEffect(() => {
    if (!isMobile && !isTablet) setIsClosed(true)
  }, [isMobile, isTablet])

  return (
    <Header
      divider={divider}
      title={title}
      contentStyles={{ right: { position: "relative" } }}
      CenterContentWrapper={StyledCenterContent}
      leftContent={<Contents.ChapterLeftContent />}
      centerContent={
        <>
          {!isMobile && !isTablet && <Contents.ChapterCenterContent />}
          {(isLaptop || isMobile) && progress ? progress : null}
        </>
      }
      rightContent={
        <>
          {!isLaptop && !isMobile && progress ? progress : null}
          {!isMobile && !isTablet ? (
            <Contents.ChapterRightContent />
          ) : (
            <Hamburger
              transform={!isClosed}
              onClick={toggleClosed}
              type="button"
              aria-controls="header-dropdown-menu"
              aria-expanded={!isClosed}
            />
          )}
        </>
      }
      dropdownLayerVisible={!isClosed}
      dropdownLayerContent={<Contents.BasicDropdownItems />}
      showHypothesisButton={showHypothesisButton}
    />
  )
}

AdvancedHeader.defaultProps = {
  showHypothesisButton: false,
}

const StyledCenterContent = styled(CenterContent)`
  justify-content: center;

  & > * + * {
    margin-left: 2.8rem;

    ${up("xl")} {
      margin-left: 5rem;
    }

    ${up("xxl")} {
      margin-left: 11rem;
    }
  }
`

export default AdvancedHeader
