import { up } from "styled-breakpoints"
import styled from "styled-components"
import Divider from "../divider"
import { FlexRow } from "../system/flex-row"

export const HeadingContainer = styled(FlexRow)`
  flex-basis: 100%;
  min-height: 8.9rem;
  align-items: center;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  position: relative;

  ${up("md")} {
    min-height: 7.4rem;
  }

  ${up("xl")} {
    min-height: 11.8rem;
  }
`

export const BottomBorder = styled(Divider)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`
