import { between, only, up } from "styled-breakpoints"
import styled from "styled-components"
import mdx from "../../components/mdx"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"
import Eye from "../../images/eye.inline.svg"

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 0 1.8rem;

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("lg")} {
    display: initial;
    flex: 5;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("lg")} {
    flex: 7;
    padding-left: 0;
  }
`

export const ReadCTA = styled(FlexRow)`
  justify-content: flex-end;
  text-align: end;
  margin-top: 4rem;
`

export const EyeIcon = styled(Eye)`
  margin-left: 2rem;
  transform: translateY(0.3rem);

  ${only("md")} {
    ${between("sm", "lg", "portrait")} {
      margin-left: 3rem;
      transform: scale(1.4) translateY(-0.15rem);
    }
  }

  ${up("xl")} {
    transform: scale(1.2);
  }

  ${up("xxl")} {
    transform: scale(1.4) translateY(-0.15rem);
  }
`

export const FAQLink = styled(mdx.a)`
  font-family: inherit;
  font-size: inherit;
  font-style: italic;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
`
