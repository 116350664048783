import { PageProps } from "gatsby"
import React from "react"
import { ThemeProvider } from "styled-components"
import ContinueLink from "../components/continue-link"
import Divider from "../components/divider"
import AdvancedHeader from "../components/header/advanced"
import PageHeading from "../components/page-heading"
import Seo from "../components/seo"
import { FlexRow } from "../components/system/flex-row"
import { Typography } from "../components/system/typography"
import GlobalStyles from "../styles/globals"
import * as Styled from "../styles/pages/hypothesis"
import { blueTheme } from "../styles/themes"

const HypothesisPage: React.FC<PageProps> = () => (
  <ThemeProvider theme={blueTheme}>
    <Seo title="Adnotowanie i komentowanie w Hypothesis" />
    <GlobalStyles variant="blue" />
    <AdvancedHeader divider progress={<ContinueLink />} />
    <Styled.Layout as="main">
      <PageHeading>
        Adnotowanie&nbsp;i komentowanie&nbsp;w Hypothesis
      </PageHeading>
      <Styled.Spacer />
      <Styled.Content>
        <FlexRow>
          <Typography
            variant="body"
            typo="azo"
            my={{ xs: "5.8rem", lg: "9rem" }}
          >
            Ta książka cyfrowa używa wtyczki Hypothesis, aby umożliwić
            udostępnianie adnotacji i komentarzy. Jeśli masz już konto
            Hypothesis, możesz zalogować się za pomocą przycisku Hypothesis po
            prawej stronie ekranu. Po zalogowaniu możesz zaznaczać swoje
            adnotacje bezpośrednio w tekście i dodawać komentarze za pomocą
            paska narzędzi, który pojawi się po prawej stronie. Aby dowiedzieć
            się więcej na temat korzystania z programu Hypothesis, odwiedź
            sekcję{" "}
            <Styled.FAQLink
              href="https://web.hypothes.is/help/"
              target="_blank"
            >
              Hypothesis FAQ
            </Styled.FAQLink>
            .
          </Typography>
        </FlexRow>
        <Divider mb={{ xs: "4.4rem", lg: "5.6rem" }} />
        <Styled.ReadCTA>
          <Typography variant="caption" fontWeight="normal">
            Rozpocznij czytanie
            <Styled.EyeIcon role="img" aria-label="Oko" />
          </Typography>
        </Styled.ReadCTA>
      </Styled.Content>
      <Divider
        mt={{ xs: "4.4rem", lg: "10rem" }}
        mb={{ xs: "5rem", md: "7rem" }}
      />
    </Styled.Layout>
  </ThemeProvider>
)

export default HypothesisPage
