import React, { PropsWithChildren } from "react"
import { Typography } from "../system/typography"
import * as Styled from "./styles"

type Props = {}

const PageHeading: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <Styled.HeadingContainer as="header">
      <Typography
        as="h1"
        variant="h2"
        my={{ xs: "2.4rem", md: "2.4rem", lg: "3rem" }}
      >
        {children}
      </Typography>
      <Styled.BottomBorder />
    </Styled.HeadingContainer>
  )
}

export default PageHeading
